import React from 'react'

import { Container, Section } from 'components/layout'
import { Row, Column12 } from 'components/ui/Grid'
import { Headline1, Headline2, Headline3, BodyCopy } from 'components/ui/Typography'

const GridPage = () => {
  return (
    <Section>
      <Container>
        <Row>
          <Column12 initial={6}>
            <Headline2>50%</Headline2>
          </Column12>
          <Column12 initial={6}>
            <Headline2>50%</Headline2>
          </Column12>
        </Row>
        <Row>
          <Column12 initial={12} medium={2} desktop={3}>
            <Headline2>Some Left </Headline2>
          </Column12>
          <Column12 initial={12} medium={8} desktop={4}>
            <Headline2>Right Combinations</Headline2>
          </Column12>
        </Row>
        <Row>
          <Column12 initial={10} medium={8} desktop={3} className="align--right">
            <Headline2>I`m waaaay right</Headline2>
          </Column12>
        </Row>
        <Row>
          <Column12 initial={8} className="align--center">
            <Headline3>Mr. Center here.</Headline3>
          </Column12>
        </Row>
        <Row>
          <Column12 initial={12} medium={8} className="align--center-middle">
            <BodyCopy>Mrs. Vertical Center here.</BodyCopy>
          </Column12>
          <Column12 initial={12} medium={4}>
            <Headline1>Holiding the entire height like it`s thin air. </Headline1>
          </Column12>
        </Row>
        <Row>
          <Column12 initial={3} />
          <Column12 initial={1}>
            <BodyCopy>Lonely and a bit offset.</BodyCopy>
          </Column12>
        </Row>
        <Row>
          {[...Array(12).keys()].map((el, i) => (
            <Column12 key={i} initial={6} medium={6} desktop={1}>
              <BodyCopy>No: {i + 1}</BodyCopy>
            </Column12>
          ))}
        </Row>
      </Container>
    </Section>
  )
}

export default GridPage
